import React, { useEffect, useState } from 'react';
import classnames from 'classnames';

export interface SpinnerProps {
  className?: string;
  /** If true, the spinner the will show immediately, not after `delay`. Defaults to false. */
  immediate?: boolean;
  /** How long should the spinner wait before showing. Defaults to 1000. */
  delay?: number;
}

export default function Spinner({ className, immediate, delay }: SpinnerProps) {
  let [visible, setVisible] = useState(Boolean(immediate));

  useEffect(() => {
    let timer = setTimeout(() => {
      setVisible(true);
    }, delay ?? 200);
    return () => clearTimeout(timer);
  }, [delay]);

  if (!visible) {
    return null;
  }

  return (
    <div
      className={classnames(
        'flex flex-auto items-center self-center content-center justify-center',
        className
      )}
    >
      <div className="relative" style={{ width: '1em', height: '1em' }}>
        <svg
          className="absolute top-0 left-o animate-spin"
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
        >
          <g
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path
              d="M12,2.0400002 C6.499244,2.0400002 2.0400002,6.499244 2.0400002,12 C2.0400002,17.500756 6.499244,21.9599998 12,21.9599998 L12,21.9599998 C17.500756,21.9599998 21.9599998,17.500756 21.9599998,12"
              id="Path"
              stroke="currentColor"
              strokeWidth="2.5"
            />
          </g>
        </svg>
      </div>
    </div>
  );
}
