import React, { useState, CSSProperties, useEffect } from 'react';
import classnames from 'classnames';
import Link from 'next/link';
import { useMeasure, useMountedState } from 'react-use';
import Spinner from 'components/ui/Spinner';
import { animated, useTransition } from 'react-spring';

export interface ButtonProps {
  className?: string;
  unstyled?: boolean;
  style?: CSSProperties;
  appearance?: 'default' | 'primary' | 'danger';
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  active?: boolean;
  busy?: boolean;
  to?: string;
  rel?: string;
  openInNewTab?: boolean;
  type?: 'submit' | 'button';
  disabled?: boolean;
  children?: React.ReactNode;
  onClick?(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): Promise<unknown> | void;
}

const innerSpacing = {
  xs: 1,
  sm: 2,
  md: 3,
  lg: 4,
  xl: 5
};

const iconSizing = {
  xs: 3,
  sm: 4,
  md: 5,
  lg: 6,
  xl: 8
};

export default function Button(props: ButtonProps) {
  let {
    children,
    onClick,
    className,
    unstyled,
    appearance,
    active,
    busy: externalBusy,
    to,
    openInNewTab,
    disabled,
    type,
    size,
    ...otherProps
  } = props;

  let [outerRef, { width, height }] = useMeasure();
  let isMounted = useMountedState();
  let [internalBusy, setInternalBusy] = useState(false);
  let busy = internalBusy || externalBusy;

  let busyFade = useTransition(busy, busy => String(busy), {
    unique: true,
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: {
      mass: 0.1,
      tension: 374,
      friction: 7,
      clamp: true
    }
  });

  appearance = appearance ?? 'default';
  size = size ?? 'md';

  let outerClasses = classnames(
    'flex rounded cursor-pointer',
    busy && 'cursor-wait opacity-50',
    disabled && 'opacity-50 pointer-events-none',
    className
  );
  let outerStyles: CSSProperties = {
    boxShadow: `
      rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(0, 0, 0, 0.12) 0px 1px 1px 0px,
      rgba(60, 66, 87, 0.16) 0px 0px 0px 1px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(60, 66, 87, 0.08) 0px 2px 5px 0px
    `.trim()
  };
  if (busy && width > 0 && height > 0) {
    outerStyles.width = width;
    outerStyles.height = height;
  }

  let classes = classnames('relative', busy && 'justify-center');

  if (!unstyled) {
    classes = classnames(
      classes,
      'flex flex-auto items-center justify-center',
      'border border-transparent',
      'font-semibold tracking-wider uppercase',
      'focus:outline-none cursor-pointer',
      'transition ease-in-out duration-150',
      // Size
      size === 'xs' && 'px-1.5 py-0.5 text-xs leading-4 rounded',
      size === 'sm' && 'px-2 py-1 text-xs leading-4 rounded',
      size === 'md' && 'px-3 py-2 text-xs leading-5d',
      size === 'lg' && 'px-4 py-3 text-sm leading-6d',
      size === 'xl' && 'px-5 py-4 text-sm leading-6d'
    );

    switch (appearance) {
      case 'default':
        classes = classnames(
          classes,
          `text-yellow-700 focus:shadow-outline-yellow border border-transparent rounded`,
          active ? `bg-gray-50` : `bg-white`
        );
        break;

      case 'primary':
        classes = classnames(
          classes,
          `text-white focus:border-gray-400 focus:shadow-outline-gray rounded`,
          active
            ? `bg-yellow-400`
            : `bg-gray-900 hover:bg-cool-gray-800 active:bg-cool-gray-700`
        );
        break;

      case 'danger':
        classes = classnames(
          classes,
          `text-white focus:border-red-700 focus:shadow-outline-red rounded`,
          active
            ? `bg-red-700`
            : `bg-red-600 hover:bg-red-500 active:bg-red-700`
        );
        break;
    }
  }

  if (openInNewTab) {
    // TODO: these should correctly model html attribute types vs. resorting to any
    (otherProps as any).target = '_blank';
    (otherProps as any).rel += ' noopener nofollow noreferrer';
  }

  let showChildren = !busy;

  let outerProps = {
    className: outerClasses,
    style: outerStyles,
    ref: outerRef
  };

  if (to) {
    if (to[0] !== '/') {
      let protocolMatch = to.match(/^\w+:/);
      if (!protocolMatch) {
        to = `http://${to}`;
      }
      return (
        <span {...outerProps}>
          <a href={to} className={classes} {...otherProps}>
            {showChildren && children}
          </a>
        </span>
      );
    }
    return (
      <span {...outerProps}>
        <Link href={to}>
          <a className={classes} {...otherProps}>
            {showChildren && children}
          </a>
        </Link>
      </span>
    );
  }

  return (
    <span {...outerProps}>
      <button
        className={classes}
        type={type || 'button'}
        onClick={async e => {
          let result = onClick?.(e);
          if (result && result.then && isMounted()) {
            setInternalBusy(true);
            await result;
            isMounted() && setInternalBusy(false);
          }
        }}
        disabled={busy || disabled}
        {...otherProps}
      >
        {busyFade.map(({ item, key, props }) => (
          <animated.div
            key={key}
            style={props}
            className={classnames(item && 'absolute inset-0 flex')}
          >
            {item ? <Spinner immediate /> : children}
          </animated.div>
        ))}
      </button>
    </span>
  );
}
